<template>
  <div class="h-full">
    <iframe class="border-0 w-full h-full" :src="$route.meta.url"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style></style>
